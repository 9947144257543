@import "../../variables.scss";

$max-width: 1600px;

.container {
  margin-left: 0;
  max-width: $max-width;
  padding-left: 0;
  padding-right: 0;
}

body,
.wrapper {
  background: rgb(34, 34, 34);
  color: white;
}

.content {
  margin-top: 50px;
}

.sidebar {
  position: fixed;
  height: 100%;
  padding-left: 0;
  width: $max-width / 4;
  z-index: 130;
}

.logo {
  width: 130px;
  margin-bottom: 30px;
  margin-top: 45px;

  img {
    width: 100%;
    height: auto;
  }
}

.leftWrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 100px;
  width: 100%;
  height: 100%;
  max-width: 300px;
  background: rgb(21, 21, 21);
  border-right: 0;
}

.current {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.instructions {
  color: rgba(white, 0.7);
  font-size: 13px;
  font-weight: 500;
  padding-top: 20px;
}

.rightWrapper {
  margin-top: 170px;
}
