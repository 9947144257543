@import "../../../variables.scss";

.wrapper {
  &.hasContent,
  &.hasValue,
  &.active {
    .placeholder {
      font-size: 9px;
      top: 10px;
      text-transform: uppercase;
    }
  }

  &.hasIcon {
    .placeholder {
      padding-left: 25px;
    }

    .input {
      padding-left: 38px;
    }
  }

  &.hasTitle {
    margin-top: 10px;
  }
}

.inputWrapper {
  position: relative;
}

.preview {
  height: 52px;
  padding-top: 4px;
  position: relative;

  .value {
    font-size: 14px;
    height: 20px;
  }

  &:not(.hasIcon) {
    .value {
      border-left: 2px solid $green;
      padding-left: 10px;
    }
  }

  &.hasIcon {
    .previewContent {
      margin-left: 25px;
      display: inline-block;
      width: calc(100% - 40px);
    }
  }
}

.previewContent {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  line-height: 24px;
  font-size: 14px;
  height: 100%;
}

.input {
  height: 50px;
  padding: 23px 15px 10px 15px;
  width: 100%;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  transition: all 0.1s ease-out;

  &.small {
    height: 25px;
    padding: 3px 6px;
  }
}

.placeholder {
  top: 16px;
  font-size: 12px;
  position: absolute;
  margin-left: 15px;
  transition: all 0.1s ease-out;
  pointer-events: none;
  letter-spacing: 0.5px;
  line-height: 13.5px;
}

.icon {
  font-size: 16px;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  position: absolute;
}

.iconWrapper {
  display: inline-block;
  margin-right: 10px;
}

.title {
  color: #ccc;
  font-size: 9px;
  display: block;
  height: 18px;
  margin-bottom: 2px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

:global(.dark),
:global(.external) {
  .wrapper {
    &.hasContent {
      .placeholder {
        color: rgb(102, 102, 102);
      }
    }

    &:hover,
    &.active {
      .input {
        border: 1px solid rgb(102, 102, 102);
      }

      .placeholder {
        color: rgb(82, 202, 154);
      }
    }

    &.disabled {
      pointer-events: none;

      .input {
        background-color: rgba($theme-dark--black, 0.5);
        border: 1px solid rgba(61, 61, 61, 0.5);
        color: rgba(white, 0.3);
      }

      .placeholder {
        color: rgba(102, 102, 102, 0.5);
      }
    }
  }

  .icon {
    color: rgba(white, 0.5);
  }

  .iconWrapper {
    .icon {
      color: $green;
    }
  }

  .placeholder {
    color: $green;
  }

  .input {
    background: $theme-dark--black;
    border: 1px solid rgb(61, 61, 61);
    color: rgba(255, 255, 255, 0.9);
    caret-color: $green;
    transition: border 200ms ease;

    &.error {
      border-bottom: 1px solid $error;
    }
  }

  .title {
    color: rgba(159, 159, 159, 0.5);
  }

  .value {
    color: rgba(255, 255, 255, 0.7);
  }

  .preview {
    border-bottom: 1px solid rgb(74, 74, 74);

    &.nested {
      border-bottom: 0;
    }
  }
}

:global(.light) {
  .wrapper {
    &.hasContent {
      .placeholder {
        color: rgba(black, 0.5);
      }
    }

    &:hover,
    &.active {
      .input {
        border: 1px solid rgba(black, 0.3);
      }

      .placeholder {
        color: rgba(black, 0.5);
      }
    }

    &.disabled {
      pointer-events: none;

      .input {
        border: 1px solid rgba(black, 0.15);
        color: #333333;
      }

      .placeholder {
        color: rgba(black, 0.5);
      }
    }
  }

  .icon {
    color: rgba(white, 0.5);
  }

  .iconWrapper {
    .icon {
      color: rgba(black, 0.5);
    }
  }

  .placeholder {
    color: rgba(black, 0.5);
  }

  .input {
    border: 1px solid rgba(black, 0.15);
    color: #333333;
    caret-color: rgba(black, 0.5);
  }

  .title {
    color: rgba(black, 0.75);
  }

  .value {
    color: #333333;
  }

  .preview {
    border-bottom: 1px solid rgba(black, 0.1);

    &.nested {
      border-bottom: 0;
    }
  }
}

@mixin print-style {
  .wrapper:not(.hasValue):not(.hasContent) {
    display: none;
  }

  .wrapper:hover,
  .wrapper.active {
    .input {
      border: 0 !important;
    }
  }

  .preview {
    height: initial;
    border-bottom: 0;

    .value {
      padding-left: 0 !important;
    }

    &.hasIcon {
      .previewContent {
        margin-left: 0;
        display: flex;
      }
    }

    &:not(.hasIcon) {
      .value {
        border-left: 0 !important;
      }
    }
  }
  :global(.dark),
  :global(.external) {
    .preview {
      border-bottom: 0;
    }
    .input {
      background: none;
      border: 0;
    }
  }
  .previewContent {
    flex-direction: row;
    width: 100% !important;

    .title {
      font-size: 14px;
      font-weight: bold;
      padding-right: 5px;
      &:after {
        content: ":";
      }
    }
  }

  .iconWrapper {
    display: none;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    span {
      position: relative;
      top: unset !important;
      margin-left: 0;
      font-size: 14px !important;
      padding-right: 5px;
      text-transform: capitalize !important;
      font-weight: bold !important;
      &:after {
        content: ":";
      }
    }

    .input {
      height: unset;
      padding: 0;
      border: 0;
      background: none;
      pointer-events: none !important;
    }
  }
}

:global(.objectDataPreview) {
  @include print-style;
}

@media print {
  @include print-style;
}
