@import "../../../variables.scss";

.wrapper {
  &.inline,
  &.inline > * {
    display: inline;
  }

  .h1 {
    font-size: 36px;
    font-weight: bold;
  }

  .h2 {
    font-size: 24px;
    font-weight: bold;

    &.light {
      color: white;
    }
  }

  .h3 {
    font-size: 14px;
    font-weight: normal;
  }

  .h3external {
    font-size: 24px;
  }

  .h4 {
    font-size: 11px;
    margin-bottom: 0.2rem;
  }

  .subtitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    display: block;
  }

  .tiny {
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    display: block;
  }
}

:global(.dark),
:global(.external) {
  .wrapper {
    .h1 {
      color: rgba(white, 0.7);
    }

    .h2 {
      color: rgba(white, 0.7);
    }

    .h3 {
      color: rgba(white, 0.3);

      &.light {
        color: rgba(white, 0.7);
      }
    }

    .h4 {
      color: rgba(white, 0.3);

      &.light {
        color: rgba(white, 0.7);
      }
    }

    .subtitle {
      color: rgba(white, 0.5);
    }

    .tiny {
      color: rgba(white, 0.5);
    }

    &.edit {
      .h3 {
        color: rgba(white, 0.7);
      }
    }
  }
}

:global(.light) {
  .wrapper {
    .h1 {
      color: rgba(black, 0.75);
    }

    .h2 {
      color: rgba(black, 0.75);
    }

    .h3 {
      color: rgba(black, 0.8);

      &.light {
        color: rgba(black, 0.75);
      }
    }

    .h4 {
      color: rgba(black, 0.7);

      &.light {
        color: rgba(black, 0.75);
      }
    }

    .subtitle {
      color: black;
    }

    .tiny {
      color: rgba(black, 0.5);
    }

    &.edit {
      .h3 {
        color: rgba(black, 0.75);
      }
    }
  }
}

@mixin print-style {
  .wrapper:not(.hasChildren) {
    display: none;
  }
}

:global(.objectDataPreview) {
  @include print-style;
}

@media print {
  @include print-style;
}
