@import "../../variables.scss";

.modal {
  position: fixed;
  z-index: 900;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  overflow-y: scroll;
  padding-top: 51px;
  padding-bottom: 85px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.overlay {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  z-index: 950;
  top: 0;
  left: 0;
}

.wrapper {
  border-radius: 4px;
  z-index: 850;
  hr {
    margin: 0;
  }

  &.createMode {
    .header,
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  :global(.row) {
    margin-right: -5px;
    margin-left: -5px;
  }

  :global(.col-1),
  :global(.col-2),
  :global(.col-3),
  :global(.col-4),
  :global(.col-5),
  :global(.col-6),
  :global(.col-7),
  :global(.col-8),
  :global(.col-9),
  :global(.col-10),
  :global(.col-11),
  :global(.col-12),
  :global(.col) {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.buttonsWrapper {
  display: flex;
}

.button {
  margin: 16px 8px 0px 8px;
}

.centerRow {
  display: flex;
  justify-content: center;
}

:global(.dark) {
  .modalContent {
    padding: 25px;
  }
  .wrapper {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    &.open {
      background: rgb(21, 21, 21);
      border: 1px solid #1f1f1f;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

      hr {
        border-bottom: 1px solid #1f1f1f;
      }

      &.createMode {
        background: transparent;
        border: none;
      }
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.9);
  }

  .title {
    color: white;
  }

  .content {
    color: $grey;
  }

  .subtitle {
    color: #9f9f9f;
  }

  .actions {
    &,
    svg {
      color: rgba(255, 255, 255, 0.3);
    }
    line-height: 75px;
  }
}

:global(.light) {
  .modalContent {
    padding: 25px;
    color: $theme-light--black;
  }
  .wrapper {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    &.open {
      background: rgb(21, 21, 21);
      border: 1px solid #1f1f1f;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

      hr {
        border-bottom: 1px solid #1f1f1f;
      }

      &.createMode {
        background: transparent;
        border: none;
      }
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .title {
    color: $theme-light--black;
  }

  .content {
    color: $grey;
  }

  .subtitle {
    color: #9f9f9f;
  }

  .actions {
    &,
    svg {
      color: rgba(255, 255, 255, 0.3);
    }
    line-height: 75px;
  }

  .button {
    color: white;
  }
}
