@import "../../../variables.scss";

.button {
  background: transparent;
  height: 50px;
  border-radius: 2px;
  font-size: 16px;
  display: inline-flex;
  position: relative;
  min-width: 180px;
  user-select: none;
  // padding: 0 4px; // This affects all buttons...

  &.isMulti {
    .innerButton {
      width: 75%;
    }
  }

  &.small {
    height: 40px;
    min-width: 140px;
  }

  &.tiny {
    height: 20px;
    min-width: 50px;
    font-size: 11px;
  }

  &.wide {
    min-width: 150px;
    font-size: 11px;
  }

  &.primary {
    &.active {
      .innerButton:after {
        display: block;
      }
    }

    .innerButton {
      &:hover {
        &:after {
          display: block;
        }
      }
    }
  }

  &.disabled {
    background: transparent;
    box-shadow: none;
    pointer-events: none;
  }

  &.inProgress {
    pointer-events: none;
  }
}

.active {
  transition: transform 0.1s ease-out;
  transform: scaleX(1.05);
}

.progress {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 2px;
}

.title {
  //position:absolute;
  //left:50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  // white-space: nowrap;
}

.progressEnter {
  width: 0;
  &.progressEnterActive {
    transition: width 10s cubic-bezier(0.19, 1, 0.22, 1);
    width: 100%;
  }
}

.progressExit {
  width: 100%;
  &.progressExitActive {
    transition: width 10s cubic-bezier(0.19, 1, 0.22, 1);
    width: 0;
  }
}

.animationEnter {
  max-height: 0;
  overflow: hidden;
  background-color: inherit;
  &.animationEnterActive {
    transition: max-height 0.3s ease-out;
    max-height: 200px;
  }
}

.animationExit {
  max-height: 200px;
  overflow: hidden;
  background-color: inherit;
  &.animationExitActive {
    transition: max-height 0.3s ease-out;
    max-height: 0;
  }
}

.innerButton {
  padding: 0 5px;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.5px;
  position: relative;
  top: 0;
  vertical-align: baseline;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
  }

  &.disabled {
    background: transparent;
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }
}

.arrow {
  width: 25%;
  position: relative;
  height: 100%;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    transition: transform 0.3s ease;
  }

  &.isOpen {
    &:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  &.disabled {
    border-left-color: transparent;
    &:after {
      cursor: default;
      pointer-events: none;
    }
  }
}

.menu {
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  background: inherit;
  z-index: 20;
  max-height: 600px;

  button {
    display: block;
    height: 50px;
    padding: 0 15px;
    width: 100%;
    text-align: left;
    font-size: 14px;
  }
}

.icon {
  display: none;
}

:global(.dark) {
  .button {
    background: transparent;
    background-color: #238c55;

    &.primary {
      background: $green;
      border: solid 1px rgb(35, 140, 85);
      box-shadow: 0 5px 10px 0 rgba($green, 0.25);

      &.disabled {
        border: 1px solid rgba(white, 0.3);
        background: transparent;
        box-shadow: none;

        .title {
          color: rgba(white, 0.3) !important;
        }
      }

      .title {
        color: white !important;
      }

      .progress {
        background-color: rgba(white, 0.2);
      }
    }

    &.tertiary {
      background: transparent;
      border: solid 1px $green;
      color: $green;
      box-shadow: 0 5px 10px rgba($green, 0.15);

      &:hover,
      &.active {
        .title {
          color: rgba(82, 202, 154, 0.95);
        }
        border: solid 1px rgba(82, 202, 154, 0.95);
      }

      &.disabled {
        border: 1px solid rgba(white, 0.1);
        .title {
          color: rgba(white, 0.15) !important;
        }
      }

      .title {
        color: rgba($green, 0.95);
      }

      .progress {
        background-color: rgba($green, 0.2);
      }
    }

    &.secondary {
      background: transparent;
      border: solid 1px rgba(white, 0.3);
      color: rgba(white, 0.5);

      &:hover,
      &.active {
        color: rgba(white, 0.9);
        border: solid 1px rgba(white, 0.5);
      }

      &.disabled {
        color: rgba(white, 0.15);
        border: 1px solid rgba(white, 0.1);
      }

      &.isLoading {
        color: rgba(white, 0.5);
      }

      .progress {
        background-color: rgba(white, 0.1);
      }
    }
  }

  .innerButton {
    &:after {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.disabled {
      .title {
        color: rgba(white, 0.15);
      }
    }
  }

  .arrow {
    border-left: 1px solid #238c55;

    &:after {
      border-bottom: 8px solid white;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &.disabled {
      border-left-color: transparent;
      &:after {
        border-bottom-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .menu {
    button {
      border-top: 1px solid rgba(black, 0.1);
      &:hover {
        color: rgba(white, 0.9);
        border: solid 1px rgba(white, 0.5);
      }
    }
  }
}

:global(.light) {
  .button {
    background: transparent;
    background-color: #238c55;

    &.secondary {
      background: transparent;
      border: 1px solid rgba($theme-light--black, 0.6);
      color: rgba(black, 0.5);

      &:hover,
      &.active {
        color: rgba(black, 0.9);
        border: 1px solid $theme-light--black;
      }

      &.disabled {
        color: rgba(black, 0.15);
        border: none;
      }

      &.isLoading {
        color: rgba(black, 0.5);
      }

      .progress {
        background-color: rgba(black, 0.1);
      }
    }

    &.primary {
      background: $green;
      border: solid 1px rgb(35, 140, 85);
      box-shadow: 0 5px 10px 0 rgba($green, 0.25);
      color: white;
      &.disabled {
        border: 1px solid rgba(black, 0.3);
        background: transparent;
        box-shadow: none;

        .title {
          color: rgba(black, 0.3);
        }
      }

      .progress {
        background-color: rgba(black, 0.2);
      }
    }

    &.tertiary {
      background: transparent;
      border: solid 1px $green;
      color: $green;
      box-shadow: 0 5px 10px rgba(black, 0.15);

      &:hover,
      &.active {
        .title {
          color: rgba(82, 202, 154, 0.95);
        }
        border: solid 1px rgba(82, 202, 154, 0.95);
      }

      &.disabled {
        border: 1px solid rgba(white, 0.1);
        .title {
          color: rgba(white, 0.15);
        }
      }

      .title {
        color: rgba($green, 0.95);
      }

      .progress {
        background-color: rgba($green, 0.2);
      }
    }
  }

  .innerButton {
    &:after {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.disabled {
      .title {
        color: rgba(white, 0.15);
      }
    }
  }

  .arrow {
    border-left: 1px solid #238c55;

    &:after {
      border-bottom: 8px solid white;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &.disabled {
      border-left-color: transparent;
      &:after {
        border-bottom-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .menu {
    button {
      border-top: 1px solid rgba(black, 0.1);
      &:hover {
        color: rgba(white, 0.9);
        border: solid 1px rgba(white, 0.5);
      }
    }
  }
}

@media (max-width: 980px) {
  .icon {
    display: block;
  }

  .title.hasIcon {
    display: none;
  }

  .button.tiny {
    min-width: 20px;
  }
}
