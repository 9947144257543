@import "../../variables.scss";

body {
  background: #eee;
  font-family: karmilla;
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact !important;

  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: rgba(216, 216, 216, 0.1);
    border-radius: 2.5px;
  }

  scrollbar-face-color: rgb(21, 21, 21);
  scrollbar-track-color: rgb(34, 34, 34);
  scrollbar-arrow-color: rgb(34, 34, 34);
  scrollbar-shadow-color: rgb(34, 34, 34);
}

:global(.locked) {
  overflow: hidden;
}

button {
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  border-radius: 0;
}

a,
button,
input,
textarea,
div[role="button"] {
  &:focus,
  &:active,
  &:visited,
  &:hover {
    outline: none;
  }
}

a {
  color: inherit;
  &:focus,
  &:active,
  &:visited,
  &:hover {
    color: inherit;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ol {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.error {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px;
  background: rgba($error, 0.9);
  z-index: 1000;
  color: white;

  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.noticeWrapper {
  position: fixed;
  width: 350px;
  top: 20px;
  left: 20px;
  z-index: 1001;
}

.enter {
  opacity: 0;
  overflow: hidden;

  &.enterActive {
    transition: opacity 0.15s ease-out;
    opacity: 1;
    white-space: nowrap;
  }
}

.noticeExit {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;

  &.noticeExitActive {
    transition: max-width 0.3s ease-out;
    max-width: 0;
  }
}

.switchWrapper {
  position: relative;
}

.pageSliderLeftEnter {
  transform: translate3d(100%, 0, 0);
}
.pageSliderLeftEnter.pageSliderLeftEnterActive {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-out;
}
.pageSliderLeftExit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.pageSliderLeftExit.pageSliderLeftExitActive {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  transition: all 500ms ease-out;
}

.pageSliderRightEnter {
  transform: translate3d(-100%, 0, 0);
}
.pageSliderRightEnter.pageSliderRightEnterActive {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-out;
}
.pageSliderRightExit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.pageSliderRightExit.pageSliderRightExitActive {
  transform: translate3d(-100%, 0, 0);
  opacity: 0;
  transition: all 500ms ease-out;
}

.routeSection {
  position: absolute;
  width: 100%;
  top: 100px;
}

:global(.text-center) {
  text-align: center;
}

:global(.input-invalid) {
  color: red !important;
}

:global(.show-print) {
  display: none;
}

:global(.previewOpen) {
  div {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
}

@mixin print-style {
  :global(.no-print) {
    display: none;
  }

  :global(.show-print) {
    display: inline;
  }

  * {
    color: black !important;
  }
}

:global(.objectDataPreview) {
  @include print-style;
}

@media print {
  @include print-style;
}
