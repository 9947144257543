@import "../../variables.scss";

.wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
}
.left {
  width: 40%;
  min-width: 600px;
  position: absolute;
  height: 100%;
  z-index: 2;
}
.right {
  position: absolute;
  height: 100%;
  width: 60%;
  max-width: calc(100% - 600px);
  right: 0;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.logotype {
  margin-bottom: 70px;
  max-height: 120px;
}

.formWrapper {
  padding: 0;
  top: 45%;
  position: absolute;
  transform: translateY(-50%);
  padding: 0 15%;
  width: 100%;
}

.formTitle {
  margin-top: 60px;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}
.field {
  white-space: nowrap;
  width: 100%;
}
.button {
  padding: 12px 32px;
  font-weight: bold;
  font-size: 14px;
  width: 100px;
  cursor: pointer;
  position: relative;
  z-index: 2;

  i {
    font-size: 10px;
    margin-left: 2px;
  }
}

.inputWrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 90px);

  .input {
    width: 100%;
    padding: 3px 15px 0 10px;
    font-size: 20px;
  }
  .placeholder {
    font-size: 14px;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
}
.input,
.button {
  border: 0;
  height: 42px;
  vertical-align: top;
  font-size: 12px;
}

.error {
  font-size: 18px;
}

.title {
  @include h1;
  line-height: 0.76;
  margin-bottom: 10px;
}

.text {
  font-size: 25px;
  letter-spacing: -1px;
  line-height: 1.1;
}

.footer,
.app {
  position: absolute;
  bottom: 40px;
  z-index: 2;

  h3 {
    font-size: 25px;
    line-height: 1.1;
    margin-bottom: 20px;
  }

  .text {
    font-size: 14px;
    max-width: 380px;
    line-height: 1.46;
  }
}

.footer {
  padding: 0 15%;
}

.app {
  right: 40px;
  margin-left: 40px;
}

.hiddenInput {
  position: absolute;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.forgottenPassword {
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
}

:global(.dark) {
  $placeholderDark: rgb(167, 167, 167);

  .wrapper {
    color: white;
  }
  .left {
    background: rgb(26, 26, 26);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  }
  .right {
    background-size: cover;
    background-position: center center;
    &:after {
      background: black;
      opacity: 0.6;
    }
  }
  .button {
    background: $green;
    color: white;
    border-top-right-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    box-shadow: 0 5px 10px 0 rgba(59, 167, 110, 0.25),
      inset 0 2px 0 0 rgba(255, 255, 255, 0.25);
    border: solid 1px rgb(37, 155, 93);
  }

  .inputWrapper {
    .input {
      background: rgba(216, 216, 216, 0.19);
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
      color: white;
      box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px rgba(255, 255, 255, 0.1);
      caret-color: white;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 60px rgb(62, 62, 62) inset;
        -webkit-text-fill-color: white !important;
        border: none;
      }
    }
    .placeholder {
      color: $placeholderDark;
    }
  }

  .error {
    color: $error;
  }

  .title {
    color: white;
  }

  .text {
    color: white;
  }

  .footer,
  .app {
    h3 {
      color: white;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .text {
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      color: white;
    }
  }

  .footer {
    .text {
      color: #a7a7a7;
    }
  }

  .forgottenPassword {
    border-bottom: 1px solid rgba(white, 0.1);
  }
}

:global(.light) {
  $placeholderDark: rgb(167, 167, 167);
  $darkGrey: #122033;

  .wrapper {
    color: $theme-light--black;
  }
  .left {
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  }
  .right {
    background-size: cover;
    background-position: center center;
    &:after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1),
        rgba(81, 176, 103, 0.22)
      );
    }
  }
  .button {
    color: white;
    border-radius: 2px;
    box-shadow: 0 5px 10px 0 rgba(82, 202, 154, 0.25);
    background-color: #3ba76e;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .inputWrapper {
    .input {
      background: white;
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
      color: $darkGrey;
      border: solid 1px rgba(0, 0, 0, 0.6);
      caret-color: $theme-light--black;
      border-right: none;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 60px white inset;
        -webkit-text-fill-color: $darkGrey !important;
        background-color: white !important;
      }
    }
    .placeholder {
      color: $placeholderDark;
    }
  }

  .error {
    color: $error;
  }

  .title {
    color: $darkGrey;
  }

  .text {
    color: $darkGrey;
  }

  .footer,
  .app {
    img {
      width: 99px;
      height: 30px;
      opacity: 0.7;
      margin-bottom: 15px;
    }

    h3 {
      color: $darkGrey;
    }

    .text {
      color: $darkGrey;
    }
  }

  .forgottenPassword {
    border-bottom: 1px solid rgba(black, 0.3);
  }
}

@media (max-width: 980px) {
  .formWrapper {
    padding: 0 60px;
  }

  .inputWrapper {
    width: calc(100% - 110px);
  }

  .footer {
    padding: 0 60px;
  }
}
