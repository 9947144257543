@import "../../../variables.scss";

.wrapper {
  &.isFixed {
    .value {
      border-right: 0;
      border-left: 2px solid;
      padding-left: 5px;
    }
  }

  &:not(.isNested) {
    margin-top: 10px;
    text-align: center;
  }
}

.smallTitle {
  opacity: 0.5;
  font-size: 9px;
  line-height: 11px;
  color: #9f9f9f;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.title {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 14px;
  height: 14px;
  &.small {
    @extend .smallTitle;
  }
}

.titleWrapper {
  text-align: left;
}
.isNested {
  line-height: 25px;

  .title {
    position: absolute;
    top: -10px;
    @extend .smallTitle;
  }

  .value {
    font-size: 13px;
    white-space: nowrap;
    height: 25px;
  }
}

.icon {
  font-size: 14px;
}

.value {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  text-align: left;
}

:global(.dark),
:global(.external) {
  .wrapper {
    &.isFixed {
      .value {
        border-left-color: rgb(159, 159, 159);
      }
    }
  }

  .title {
    color: $theme-dark--white;
  }

  .value {
    color: $theme-dark--white;
  }

  .icon {
    margin-right: 10px;
    color: $green;
  }
}

:global(.light) {
  .wrapper {
    &.isFixed {
      .value {
        border-left-color: rgb(159, 159, 159);
      }
    }
  }

  .title {
    color: $theme-light--black;
  }

  .value {
    color: $theme-light--black;
  }

  .icon {
    margin-right: 10px;
    color: $green;
  }
}

@mixin print-style {
  .wrapper {
    margin-top: 0 !important;

    &:not(.hasValue) {
      display: none;
    }

    &:not(.valueSet) {
      display: none;
    }
  }

  .titleWrapper {
    display: flex;
    flex-shrink: 1;
    padding-right: 5px;

    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #222222;
    margin-bottom: 8px;
    & > div {
      display: flex;
      align-items: center;
    }

    h3 {
      margin-bottom: 0;
      font-weight: bold !important;
    }

    &:not(.hasTitle) {
      display: none;
    }
  }

  .value {
    height: auto !important;
    flex-grow: 1;
    border-right: 0 !important;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    color: #222222;
  }
}

:global(.objectDataPreview) {
  @include print-style;
}

@media print {
  @include print-style;
}
