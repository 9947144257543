@import "../../variables.scss";

.background {
  position: relative;
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 200px;
}

.header {
  margin: 50px 0 10px 0;
}

.field {
  margin-bottom: 10px;
  width: 500px;
  height: 50px;
}

.invalidField {
  border-bottom: 1px solid $error;
}

.footer {
  margin-top: 20px;
}

:global(.dark) {
  body,
  .wrapper {
    color: white;
  }
}

:global(.light) {
  body,
  .wrapper {
    background-color: rgba(white, 0.85);
    color: black;
  }

  .submit {
    color: white;
  }
  .footer {
    color: white;
  }
}
