@import "../../variables.scss";

.wrapper {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  hr {
    margin: 0;
  }

  &.createMode {
    .header,
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  :global(.row) {
    margin-right: -5px;
    margin-left: -5px;
  }

  :global(.col-1),
  :global(.col-2),
  :global(.col-3),
  :global(.col-4),
  :global(.col-5),
  :global(.col-6),
  :global(.col-7),
  :global(.col-8),
  :global(.col-9),
  :global(.col-10),
  :global(.col-11),
  :global(.col-12),
  :global(.col) {
    padding-right: 5px;
    padding-left: 5px;
  }

  @media print {
    & {
      // if supported, this will prevent page-breaks
      // in the middle of blocks
      break-inside: avoid-page;
    }
  }
}

.header,
.content {
  padding: 0 50px;
}

.header {
  height: 50px;

  &.hasHelpText {
    position: relative;
    .helpText {
      margin-top: 15px;
      position: relative;
      .informationIcon {
        position: relative;
        font-size: 12px;
        color: rgb(255, 255, 255);
        z-index: 102;
        font-style: normal;
        margin-right: 10px;
        &::before {
          display: inline-block;
          z-index: 1;
          content: "?";
          width: 16px;
          height: 16px;
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 50%;
          line-height: 14px;
          font-weight: bold;
          text-align: center;
        }
      }
      .informationBox {
        font-size: 12px;
        display: none;
        z-index: 103;
        color: white;
        background-color: black;
        padding: 8px;
        min-width: 300px;
        max-width: 550px;
        min-height: 2em;
        border-radius: 2px;
        white-space: normal;
        position: absolute;
        left: 0;

        .videoLink {
          cursor: pointer;
          svg {
            margin-right: 6px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &:hover {
        .informationBox {
          display: block;
        }
      }
    }
    .titles {
      display: flex;
      overflow: visible;
    }
    .subtitle {
      top: 20px;
      &::before {
        top: -2px;
      }
    }
  }
}

.content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.titles {
  white-space: nowrap;
  overflow: hidden;
}

.title {
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  margin-top: 18px;
  display: inline-block;
  margin-right: 16px;
  min-height: 1em;
}

.subtitle {
  font-size: 12px;
  line-height: 1;
  margin-top: 2px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.department,
.blockStatus {
  margin-right: 5px;
}

.field {
  padding-top: 20px;
}

.actions {
  text-align: right;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;

  .minimize {
    font-size: 14px;
  }

  & > * {
    display: inline-block;
    margin-left: 20px;
    line-height: 50px;
    vertical-align: middle;
  }
}
.filterActive {
  font-size: 14px;
}

.filterInactive {
  font-size: 14px;
}
.blockEnter {
  max-height: 0;
  overflow: hidden;

  &.blockEnterActive {
    transition: max-height 0.1s ease-out;
    max-height: 500px;
  }
}

.blockExit {
  max-height: 500px;
  overflow: hidden;

  &.blockExitActive {
    transition: max-height 0.1s ease-out;
    max-height: 0;
  }
}

.editFooter {
  text-align: right;
  width: 100%;
  padding: 20px 50px 20px 30px;

  > div {
    & > * {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}

.footer {
  @extend .editFooter;
  > div {
    & > * {
      margin-left: 0;
      > div {
        margin-left: 20px;
      }
    }
  }
}

.headerAction {
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;

  i {
    margin-left: 10px;
    font-size: 16px !important;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.isExternal {
  color: $green;
}

.hasComments {
  position: relative;
  &:before {
    font-size: 16px;
  }
  &:after {
    content: attr(comments);
    position: absolute;
    font-size: 11px;
    width: 100%;
    text-align: center;
    left: 0;
    top: 1px;
  }
}

.blockTitle {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.accordion {
  margin-top: 20px;
}

.uploadInput {
  display: none;
}

:global(.dark) {
  .wrapper {
    background: rgb(21, 21, 21);
    border: 1px solid #1f1f1f;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

    hr {
      border-bottom: 2px solid #1f1f1f;
    }

    &.createMode {
      background: transparent;
      border: none;
    }

    &.ghosted {
      background: rgba(21, 21, 21, 0.5);

      .title {
        color: rgba(white, 0.5);
      }

      .subtitle {
        color: rgba(#9f9f9f, 0.5);
      }
    }
  }

  @mixin print-style {
    .wrapper {
      border: 0 !important;
      margin-bottom: 10px;
      background: white;
      box-shadow: none;

      &.empty {
        display: none;
      }

      .title {
        font-weight: bold;
      }

      .subtitle {
        display: none;
      }
      hr {
        border-bottom: 0px !important;
      }
    }

    .header {
      background: #f0f0f0;
    }
  }

  @media print {
    .content {
      padding: 20px;
    }
    @include print-style;
  }

  :global(.objectDataPreview) {
    @include print-style;
  }

  .title {
    color: white;
  }

  .subtitle {
    color: #9f9f9f;
  }

  .actions {
    &,
    svg {
      color: rgba(255, 255, 255, 0.3);
    }
    line-height: 50px;
  }

  .header,
  .content {
    color: black;
  }

  .subtitle {
    color: #ccc;

    &:before {
      border-right: 1px solid rgb(151, 151, 151);
    }
  }

  .actions {
    & > * {
      &:hover {
        color: $green;
      }
    }
  }

  .filterActive {
    color: white;
    opacity: 0.9;
  }

  .filterInactive {
    color: white;
    opacity: 0.5;
  }

  .hasComments {
    &:after {
      color: white;
    }
  }

  .blockTitle {
    opacity: 0.7;
    color: #ffffff;
  }
}

.statuses {
  .title {
    font-size: 14px;
    opacity: 0.5;
    margin-top: 8px;
  }

  background-color: rgba(black, 0.15);
  height: 30px;
  padding: 0 50px;
}

:global(.light) {
  .wrapper {
    background: white;
    hr {
    }

    &.createMode {
      background: transparent;
      border: none;
    }

    &.ghosted {
      background: rgba(white, 0.5);

      .title {
        color: rgba(black, 0.5);
      }

      .subtitle {
        color: rgba(#9f9f9f, 0.5);
      }
    }

    @media print {
      border: 0 !important;
    }
  }

  .title {
    color: $theme-light--black;
  }

  .subtitle {
    color: #9f9f9f;
  }

  .actions {
    &,
    svg {
      color: $theme-light--black;
    }
    line-height: 50px;
  }

  .external {
    &.header {
      background-color: #666666;

      .title {
        color: white;
      }

      .actions {
        &,
        svg {
          color: white;
        }
      }
    }

    &.footer {
      background-color: #fafafa;
    }
  }

  .filterActive {
    color: black;
  }

  .filterInactive {
    color: $theme-light--black;
  }

  .header.hasHelpText {
    position: relative;
    .informationIcon {
      color: #9f9f9f;
      &::before {
        border: 1px solid rgba(159, 159, 159, 0.7);
      }
    }
    .informationBox {
      color: #333333;
      background-color: white;
      border: 1px solid #9f9f9f;
    }
  }

  .statuses {
    background-color: rgb(246, 246, 246);
  }
}

@media (max-width: 980px) {
  .header,
  .content {
    padding: 0 15px;
  }

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .subtitle {
    overflow: hidden;
  }
}
