@import "../../../variables.scss";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1000;
  opacity: 0;

  .loader {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .logotype img {
    width: 150px;
    margin-bottom: 20px;
    display: block;
  }
}

.showLoader {
  opacity: 1;
  z-index: 1000;
  transition: opacity 300 ease-in;
}

:global(.dark) {
  .loading {
    background: rgba(26, 26, 26, 0.7);

    &.solid {
      background: rgb(26, 26, 26);
    }
  }
}

:global(.light) {
  .loading {
    background: rgba(white, 0.7);

    &.solid {
      background: white;
    }

    .loader {
      color: $theme-light--black;
    }

    .logotype img {
      opacity: 0.7;
    }
  }
}
