@import "../../../variables.scss";

:global(.light) {
  .wrapper {
    color: rgba($theme-light--black, 0.9);
  }
  .wrapper.warning {
    background: rgba($warning, 0.9);
  }
  .wrapper.success {
    background: rgba($success, 0.9);
  }
}

.wrapper {
  padding: 15px;
  font-size: 14px;
  // font-family: 'Karla';
  position: relative;
  color: rgba(white, 0.9);

  &.warning {
    background: darken(rgba($warning, 0.9), 50%);
    border: 1px solid $warning;
  }

  &.error {
    background: rgba($error, 0.8);
    border: 1px solid $error;
  }

  &.success {
    background: darken(rgba($success, 0.9), 10%);
    border: 1px solid $success;
  }
}

.controls {
  text-align: right;
  top: 0;
  right: 10px;
  position: absolute;
}

.button {
  border: 0;
  background: 0;
  font-size: 8px;
  padding: 5px;
}

.text {
  display: inline-block;
  padding-left: 20px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
