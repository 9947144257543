/* Primary colors */
$green: rgb(59, 167, 110);
$blue: rgb(45, 142, 255);
$orange: rgb(255, 171, 0);
$grey: rgb(159, 159, 159);

/* alert colors */
$error: rgb(244, 67, 54);
$success: rgb(139, 195, 74);
$warning: rgb(255, 238, 88);

/* task priority colors */
$task-yellow: #ffee58;
$task-red: #f44336;
$task-blue: #2d8eff;
$task-disabled: #9f9f9f;

/* Constants */
$DARK: "dark";
$LIGHT: "light";

/* Dark theme */
$theme-dark--white: rgba(255, 255, 255, 0.7);
$theme-dark--black: rgb(12, 12, 12);
$theme-dark--grey-dark: rgb(21, 21, 21);

$theme-dark--shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

/* Light theme */
$theme-light--grey-white: #efefef;
$theme-light--black: rgba(0, 0, 0, 0.7);

/* Mixins for typeface styles */

// @mixin h1($theme: $DARK) {
//   font-family: "Karla";
//   font-weight: bold;
//   font-size: 36px;
//   letter-spacing: -1px;
//   line-height: 27.5px;
//   color: if($theme == $DARK, rgb(242, 242, 242), null);
// }

// @mixin h2($theme: $DARK) {
//   font-family: "Karla";
//   font-weight: bold;
//   font-size: 24px;
//   line-height: 27.5px;
//   color: if($theme == $DARK, white, null);
// }

// @mixin h3($theme: $DARK) {
//   font-family: "Karla";
//   font-weight: normal;
//   font-size: 18px;
//   line-height: 22px;
//   color: if($theme == $DARK, white, null);
// }

// @mixin h4($theme: $DARK) {
//   font-family: "Karla";
//   font-weight: normal;
//   font-size: 12px;
//   text-transform: uppercase;
//   letter-spacing:.5px;
//   color: if($theme == $DARK, rgb(159, 159, 159), null);
// }

// @mixin body($theme: $DARK) {
//   font-family: "Karla";
//   font-size: 14px;
//   line-height:20px;
//   font-weight:normal;
//   color: if($theme == $DARK, white, null);
// }

// @mixin link($theme: $DARK) {
//   font-family: "Karla";
//   font-size: 14px;
//   line-height: 20px;
//   color: if($theme == $DARK, white, null);
// }

@mixin h1($theme: $DARK) {
  font-family: "karmilla";
  font-weight: bold;
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 27.5px;
  color: if($theme == $DARK, rgb(242, 242, 242), null);
}

@mixin h2($theme: $DARK) {
  font-family: "karmilla";
  font-weight: bold;
  font-size: 24px;
  line-height: 27.5px;
  color: if($theme == $DARK, white, null);
}

@mixin h3($theme: $DARK) {
  font-family: "karmilla";
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: if($theme == $DARK, white, null);
}

@mixin h4($theme: $DARK) {
  font-family: "karmilla";
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: if($theme == $DARK, rgb(159, 159, 159), null);
}

@mixin body($theme: $DARK) {
  font-family: "karmilla";
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: if($theme == $DARK, white, null);
}

@mixin link($theme: $DARK) {
  font-family: "karmilla";
  font-size: 14px;
  line-height: 20px;
  color: if($theme == $DARK, white, null);
}

@mixin h1($theme: $LIGHT) {
  font-family: "karmilla";
  font-weight: bold;
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 27.5px;
  color: if($theme == $LIGHT, $theme-light--black, null);
}

@mixin h2($theme: $LIGHT) {
  font-family: "karmilla";
  font-weight: bold;
  font-size: 24px;
  line-height: 27.5px;
  color: if($theme == $LIGHT, $theme-light--black, null);
}

@mixin h3($theme: $LIGHT) {
  font-family: "karmilla";
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: if($theme == $LIGHT, $theme-light--black, null);
}

@mixin h4($theme: $LIGHT) {
  font-family: "karmilla";
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: if($theme == $LIGHT, rgb(159, 159, 159), null);
}

@mixin body($theme: $LIGHT) {
  font-family: "karmilla";
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: if($theme == $LIGHT, $theme-light--black, null);
}

@mixin link($theme: $LIGHT) {
  font-family: "karmilla";
  font-size: 14px;
  line-height: 20px;
  color: if($theme == $LIGHT, $theme-light--black, null);
}
