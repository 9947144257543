@import "../../../variables.scss";

@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollLeftOnce {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  margin-left: -1px; // HANDLE REMOVAL OF ANNOYING DOTS

  &.chromeFix {
    display: inline-flex; //Something has happended to inline-block in Chrome 77 that makes it break the layout when overflow is hidden.
  }

  .text1,
  .text2,
  .text3 {
    transform: translateX(100%);
    min-width: 100%;
    height: 100%;
    position: absolute;
    left: 1px; // REMOVE ANNOYING DOTS
  }

  &.scrolling {
    .text1,
    .text2,
    .text3 {
      padding-right: 20px;
    }
  }

  .text2 {
    animation-delay: 5s;
  }

  .text3 {
    transform: translateX(0%);
  }

  &.scrolling,
  &.active {
    &:hover,
    &.active {
      .text1 {
        animation: scrollLeft 10s linear 0s infinite;
      }

      .text2 {
        animation: scrollLeft 10s linear 5s infinite;
      }

      .text3 {
        animation: scrollLeftOnce 5s linear 0s 1 normal forwards;
      }
    }
  }
}

@mixin print-style {
  .wrapper {
    display: inline-block;
    overflow: visible;
    white-space: pre-wrap;
  }
  .text1 {
    position: relative !important;
  }

  .text2,
  .text3 {
    display: none;
  }
}

:global(.objectDataPreview) {
  @include print-style;
}

@media print {
  @include print-style;
}
